import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { EmailsInput } from "./EmailsInput";
import { FormErrors } from "./FormErrors";
import { MessageInput } from "./MessageInput";
import { FacebookShare } from "./FacebookShare";

type Props = {
  title?: string;
  emails: string[];
  handleEmailsChange: (data: string[]) => void;
  message: string;
  handleMessageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  errors: string[];
  isLoading?: boolean;
  gameId?: number;
};

export const InviteFriendsModalContent: React.FC<Props> = ({
  handleSubmit,
  emails,
  handleEmailsChange,
  message,
  handleMessageChange,
  errors,
  title = "Get your Friends in the game",
  isLoading,
  gameId,
}) => {
  const isErrors = (!!errors.length) || isLoading;

  return (
    <div className="modal_content_container">
      <h1>{title}</h1>
      <form onSubmit={handleSubmit}>
        <EmailsInput emails={emails} handleEmailsChange={handleEmailsChange} />
        <MessageInput
          message={message}
          handleMessageChange={handleMessageChange}
        />
        {isErrors && <FormErrors errors={errors} />}
        <div className="btn_container gap-2">
          {!!gameId &&
            <FacebookShare
              url={`${window?.location?.host || 'https://gmdynasty.com'}/replay/${gameId}`}
              hashtag="#GMDynasty"
            >
              <BlueButton
                className="bg-danger d-flex align-items-center"
                text="Share on Facebook"
              />
            </FacebookShare>
          }
          <BlueButton
            disabled={isErrors}
            text="Send"
            isLoading={isLoading}
          >
            Send
          </BlueButton>
        </div>
      </form>
    </div>
  );
};
