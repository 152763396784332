/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Footer from "../components/Common/Footer/Footer";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import NewPasswordScreen from "../screens/NewPasswordScreen/NewPasswordScreen";
import LeagueEnterScreen from "../screens/LeagueEnterScreen/LeagueEnterScreen";
import LeagueStandingScreenStyle from "../screens/LeagueStandingScreen/LeagueStandingScreen";
import FranchiseOverviewScreen from "./../screens/FranchiseOverviewScreen/FranchiseOverviewScreen";
import FranchiseDetailScreen from "../screens/FranchiseDetail/FranchiseDetailScreen";
import DraftScreen from "../screens/DraftScreen/DraftScreen";
import ResetPasswordScreen from "./../screens/ResetPasswordScreen/ResetPasswordScreen";
import DraftResultScreen from "../screens/DraftResultScreen/DraftResultScreen";
import ProtfolioNoFranchisesScreen from "../screens/ProtfolioNoFranchisesScreen/ProtfolioNoFranchisesScreen";
import ProtfolioOwnFranchiseScreen from "../screens/ProtfolioOwnFranchiseScreen/ProtfolioOwnFranchiseScreen";
import CustomizeFranchiseScreen from "./../screens/CustomizeFranchiseScreen/CustomizeFranchiseScreen";
import PurchaseFranchiseScreen from "../screens/PurchaseFranchiseScreen/PurchaseFranchiseScreen";
import ImportFranchisesScreen from "../screens/ImportFranchisesScreen/ImportFranchisesScreen";
import LeagueOverviewEliminationScreen from "../screens/LeagueOverviewElimination/LeagueOverviewEliminationScreen";
import LeagueOverviewGameResultsScreen from "../screens/LeagueGameResultsScreen/LeagueOverviewGameResultsScreen";
import LeagueOverviewHeadToHeadScreen from "../screens/LeagueOverviewHeadToHeadScreen/LeagueOverviewHeadToHeadScreen";
import FullPlayerStatScreen from "../screens/FullPlayerStat/FullPlayerStatScreen";
import FullTeamStatScreen from "../screens/FullTeamStat/FullTeamStatScreen";
import LeaguePaymentScreen from "../screens/LeaguePaymentScreen/LeaguePaymentScreen";
import AuthRoute from "./AuthRoute";
import NoAuthRoute from "./NoAuthRoute";
import MetaMaskSignupForm from "./../components/Signup/MetaMaskSignupForm";
import MintingAssetsScreen from "../screens/MintingAssetsScreen/MintingAssetsScreen";
import SettingScreen from "../screens/SettingScreen/SettingScreen";
import FranchiseTeamCreation from "../screens/FranchiseTeamCreation/FranchiseTeamCreation";
import HowitWorksScreen from "../screens/HowitWorksScreen/HowitWorksScreen";
import AboutScreen from "../screens/AboutScreen/AboutScreen";
import ContactScreen from "../screens/ContactScreen/ContactScreen";
import PrivacyScreen from "../screens/PrivacyScreen/PrivacyScreen";
import LegendFranchiseScreen from "../screens/LegendFranchiseScreen/LegendFranchiseScreen";
import NewsScreen from "../screens/NewsScreen/NewsScreen";
import MarketScreen from "../screens/MarketScreen/MarketScreen";
import FranchisePerformanceScreen from "../screens/FranchisePerformanceScreen/FranchisePerformanceScreen";
import GamePlay from "../screens/GamePlay/GamePlay";
import NotFound from "../screens/NotFound/NotFound";
import EnteredLeague from "../screens/EnteredLeague/EnteredLeague"; /* Done by Kailash */
// import PregameTeamStatScreen from "../screens/PregameStats/PregameStatScreen";
import PlayerDetailScreen from "../screens/PlayerDetail/PlayerDetailScreen";
import { JoinGameScreen } from "../screens/JoinGame/JoinGameScreen";
import Replay from "../screens/Replay/Replay";
import { LeaderboardScreen } from "screens/LeaderboardScreen/LeaderboardScreen";
import { InviteFriendsScreen } from "screens/InviteFriendsScreen";
import { InvitationConfirm } from "screens/InvitationConfirm/InvitationConfirmWrapper";
import PlayFreeScreen from "screens/PlayFreeScreen/PlayFreeScreen";
import LearnMoreScreen from "components/LearnMore/LearnMoreMain";
import NewsTemplateScreen from "screens/NewsTemplate/NewsTemplateScreen";
import { GameJoinTutorial } from "components/Tutorials/GameJoinTutorial";
import { AutoSEO } from "components/SEO/AutoSEO";
import { PurchaseTrophyScreen } from "screens/PurchaseTrophyScreen/PurchaseTrophyScreen";
import { ProfileScreen } from "screens/ProfileScreen";
import { FriendRequestScreen } from "screens/FriendRequestScreen";
import { CreateGroupScreen } from "screens/CreateGroupScreen";
import { EditGroupScreen } from "screens/EditGroupScreen";
import { GroupOverviewScreen } from "screens/GroupPage";
import { GroupRequestsScreen } from "screens/GroupRequestsPage";
import { GroupInviteScreen } from "screens/GroupInviteScreen";
import { GroupInvitationsScreen } from "screens/GroupInvitationsScreen";
import { LobbyScreen } from "screens/LobbyScreen";
import { SeasonScreen } from "screens/Season/SeasonScreen";
import { AccountDeletion } from "screens/AccountDeletion/AccountDeletion";

const AppMain: React.FC = () => (
  <Suspense fallback={<span>loading</span>}>
    <AutoSEO />
    <Switch>
      {/* LOGIN & SIGNUP FLOW */}
      <Route exact path="/" component={HomeScreen} />
      <Route exact path="/newpassword" component={NewPasswordScreen} />

      <NoAuthRoute exact path="/signin" component={HomeScreen} />
      <Route exact path="/signup">
        <Redirect to="/?signup=true" />
      </Route>
      <Route exact path="/join-free-game" component={PlayFreeScreen} />
      <NoAuthRoute
        exact
        path="/metaMask-Signup"
        component={MetaMaskSignupForm}
      />
      <Route
        exact
        path="/reset-password/:token"
        component={ResetPasswordScreen}
      />
      <Route exact path="/verify-email/:token" component={HomeScreen} />
      <Route exact path="/verify-email/" component={HomeScreen} />
      <AuthRoute
        exact
        path="/franchiseoverview/:franchiseId"
        component={FranchiseOverviewScreen}
      />
      <Route exact path="/newpassword" component={NewPasswordScreen} />
      <AuthRoute exact path="/league" component={LeagueEnterScreen} />
      <AuthRoute exact path="/stand" component={LeagueStandingScreenStyle} />
      <AuthRoute
        exact
        path="/franchisedetail/:franchiseId"
        component={FranchiseDetailScreen}
      />
      <AuthRoute
        exact
        path="/playerdetail/:playerId"
        component={PlayerDetailScreen}
      />
      <AuthRoute exact path="/draft" component={DraftScreen} />
      <AuthRoute exact path="/draftresult" component={DraftResultScreen} />
      <AuthRoute
        exact
        path="/nofranchise"
        component={ProtfolioNoFranchisesScreen}
      />
      <AuthRoute
        exact
        path="/ownfranchise"
        component={ProtfolioOwnFranchiseScreen}
      />
      <AuthRoute exact path="/leaderboard" component={LeaderboardScreen} />
      <AuthRoute
        exact
        path="/customize-franchise/:franchiseId"
        component={CustomizeFranchiseScreen}
      />
      <AuthRoute
        exact
        path="/purchasefranchise"
        component={PurchaseFranchiseScreen}
      />
      <AuthRoute
        exact
        path="/purchase-trophy"
        component={PurchaseTrophyScreen}
      />
      <AuthRoute
        exact
        path="/importfranchise"
        component={ImportFranchisesScreen}
      />
      <AuthRoute
        exact
        path="/leagueelimination"
        component={LeagueOverviewEliminationScreen}
      />
      <AuthRoute
        exact
        path="/game-results/:gid"
        component={LeagueOverviewGameResultsScreen}
      />
      {/* <AuthRoute exact path="/teamstat/:gid" component={LeagueTeamStatScreen} /> TODO: INVESTIGATE REMOVAL */} 
      {/* <AuthRoute
        exact
        path="/drivechart/:gid"
        component={LeagueDriveChartScreen} TODO: INVESTIGATE REMOVAL
      /> */}
      {/* <AuthRoute
        exact
        path="/leagueheadtohead/:gid/depthchart"
        component={() => DefensiveDepthChartScreen({ defaultTab: 'depthchart' })}
      />
      <AuthRoute
        exact
        path="/leagueheadtohead/:gid/gameplan"
        component={() => DefensiveDepthChartScreen({ defaultTab: 'gameplan' })}
      /> */}
      <AuthRoute
        exact
        path="/fullplayerstat/:franchiseId/:gid"
        component={FullPlayerStatScreen}
      />
      <AuthRoute
        exact
        path="/fullteamstat/:gid/:utid"
        component={FullTeamStatScreen}
      />
      {/* <AuthRoute
        exact
        path="/pregamestat/:gid"
        component={PregameTeamStatScreen} TODO: INVESTIGATE REMOVAL
      /> */}

      {/* SLIDE NO : 41 DRAFT */}
      <AuthRoute exact path="/draft" component={DraftScreen} />

      {/* SLIDE NO : 42 DRAFT RESULTS */}
      <AuthRoute exact path="/draftresult" component={DraftResultScreen} />

      {/* SLIDE NO : 43 LEAGUE PAYMENT */}
      <AuthRoute exact path="/leaguepayment" component={LeaguePaymentScreen} />
      <AuthRoute
        exact
        path="/leagueheadtohead"
        component={LeagueOverviewHeadToHeadScreen}
      />
      <AuthRoute exact path="/joingame/:gid" component={JoinGameScreen} />

      {/* SLIDE NO. 47 MINTING SCREEN */}
      <AuthRoute exact path="/mint" component={MintingAssetsScreen} />
      <AuthRoute exact path="/setting" component={SettingScreen} />
      <AuthRoute exact path="/account-deletion" component={AccountDeletion} />
      <AuthRoute
        exact
        path="/teamcreate/:franchiseId"
        component={FranchiseTeamCreation}
      />
      <AuthRoute exact path="/profile/:id" component={ProfileScreen} />
      <AuthRoute
        exact
        path="/profile-invitations/:id"
        component={GroupInvitationsScreen}
      />
      <AuthRoute exact path="/group-create" component={CreateGroupScreen} />
      <AuthRoute
        exact
        path="/group-edit/:groupId"
        component={EditGroupScreen}
      />
      <AuthRoute
        exact
        path="/group-invite/:groupId"
        component={GroupInviteScreen}
      />
      <AuthRoute exact path="/group/:groupId" component={GroupOverviewScreen} />
      <AuthRoute exact path="/group-requests/:groupId" component={GroupRequestsScreen} />
      <AuthRoute
        exact
        path="/friend-requests"
        component={FriendRequestScreen}
      />
      {/* <Route
        exact
        path="/teamcreate"
        component={FranchiseTeamCreation}
      /> */}

      {/* HOW IT WORKS */}
      <Route exact path="/works" component={HowitWorksScreen} />

      {/* LEARN MORE */}
      <Route exact path="/learn-more" component={LearnMoreScreen} />

      <Route exact path="/invitation-confirm" component={InvitationConfirm} />
      {/* Invites */}
      <Route exact path="/invites" component={InviteFriendsScreen} />

      {/* About Us */}
      <Route exact path="/about" component={AboutScreen} />

      {/* Contact */}
      <Route exact path="/contact" component={ContactScreen} />

      {/* Privacy */}
      <Route exact path="/privacy" component={PrivacyScreen} />

      {/* Legend Screen */}
      <Route exact path="/legend" component={LegendFranchiseScreen} />

      {/* News */}
      <Route exact path="/news" component={NewsScreen} />

      {/* News template */}
      <Route exact path="/news/:newsId" component={NewsTemplateScreen} />

      <AuthRoute exact path="/lobby" component={LobbyScreen} />

      {/* Market */}
      <Route exact path="/market" component={MarketScreen} />

      <Route exact path="/performance" component={FranchisePerformanceScreen} />
      <AuthRoute exact path="/EnteredLeague" component={EnteredLeague} />

      <Route exact path="/gameplay" component={GamePlay} />
      <Route exact path="/replay/:gid" component={Replay} />
      <AuthRoute
        exact
        path="/season/:gid"
        component={SeasonScreen}
      />

      <Route path="*" component={NotFound} />
    </Switch>
    <Footer />
    <GameJoinTutorial />
  </Suspense>
);

export default AppMain;
